let SUCCESS_RESULT_TYPE = "success";
let WARNING_RESULT_TYPE = "warning";
let ERROR_RESULT_TYPE = "error";

let bgColor = function (resultType) {
  switch (resultType) {
    case SUCCESS_RESULT_TYPE:
      return "bg-green-500";
    case WARNING_RESULT_TYPE:
      return "bg-yellow-500";
    case ERROR_RESULT_TYPE:
      return "bg-red-500";
  }
};

export { SUCCESS_RESULT_TYPE, WARNING_RESULT_TYPE, ERROR_RESULT_TYPE, bgColor };
