import { createRouter, createWebHistory } from "vue-router";
import LoginComponent from "@/components/Login.vue";
import EventsListComponent from "@/components/EventsList.vue";
import ActivityListComponent from "@/components/ActivityList.vue";
import CheckInComponent from "@/components/CheckIn.vue";
import { useAuthStore } from "@/stores/authStore";

const routes = [
  {
    path: "/",
    name: "Login",
    component: LoginComponent,
    meta: { requiresAuth: false },
  },
  {
    path: "/events",
    name: "Events",
    component: EventsListComponent,
    meta: { requiresAuth: true },
  },
  {
    path: "/events/:tour/activities",
    name: "EventActivities",
    component: ActivityListComponent,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/events/:tour/check-in/:variant",
    name: "CheckIn",
    component: CheckInComponent,
    props: true,
    meta: { requiresAuth: true },
  },
  // Ajoutez ici d'autres routes au besoin
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  if (to.meta.requiresAuth && !authStore.token) {
    // Si la route requiert une authentification et qu'aucun token n'est trouvé, rediriger vers le login
    next({ name: "Login" });
  } else if (!to.meta.requiresAuth && authStore.token) {
    // Si la route ne requiert pas d'authentification et qu'un token est trouvé, rediriger vers les événements
    next({ name: "Events" });
  } else {
    // Dans tous les autres cas, procéder normalement
    next();
  }
});

export default router;
