<template>
  <div>
    <Header>
      <template #left>
        <div class="flex items-center">
          <router-link tag="button" :to="{ name: 'Events'}" class="relative rounded-full bg-blue-700 p-1 text-blue-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-blue-gray-800">
            <ChevronLeftIcon class="h-6 w-6"></ChevronLeftIcon>
          </router-link>
        </div>
      </template>
      {{ eventStore.selectedEvent?.name }}
    </Header>

    <nav class="h-full overflow-y-auto bg-white" aria-label="Directory">
      <div v-for="activity in eventStore.activities" :key="activity.id" class="relative">
        <div class="sticky top-0 z-10 border-y border-b-gray-200 border-t-gray-100 bg-gray-50 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-900">
          <h3>
            {{ activity.code }}
          </h3>
        </div>
        <ul role="list" class="divide-y divide-gray-100">
          <li v-for="variant in activity.variants" 
            :key="variant.id" 
            class="relative flex justify-between gap-x-6 py-5 px-3"
            @click="selectVariant(variant)">
              <div class="text-sm leading-6 text-gray-900">
                <span v-if="variant.option_values.length === 0">
                    Variant par défaut
                </span>
                <span v-else>
                    {{ variant.option_values.map(option => option.name).join(', ') }}
                </span>
              </div>
              <div class="ml-auto rounded-full flex-none py-1 px-2 text-xs font-medium ring-1 ring-inset text-gray-400 bg-gray-400/10 ring-gray-400/20">
                {{ variant.checked_in_order_items_count }} / {{ variant.order_items_count }}
              </div>
              <div class="shrink-0 flex items-center">
                <ChevronRightIcon class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
              </div>
            </li>
        </ul>
      </div>
    </nav>

  </div>
</template>

<script setup>
import Header from '@/components/Header.vue';
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/vue/20/solid'

import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useEventStore } from '@/stores/eventStore';

const route = useRoute();
const router = useRouter();
const eventStore = useEventStore();

onMounted(async () => {
  const tourSlug = route.params.tour;
  if (!eventStore.selectedEvent || eventStore.selectedEvent.slug !== tourSlug) {
    await eventStore.fetchEventBySlug(tourSlug);
  }
  await eventStore.fetchActivities(tourSlug);
});


const selectVariant = (variant) => {
    eventStore.selectVariant(variant.id);
    console.log(variant, { tour: route.params.tour, variant: variant.id });
    router.push({ name: 'CheckIn', params: { tour: route.params.tour, variant: variant.id } });
};
</script>
