<template>
  <div class="min-h-screen bg-white">
    <Header>
      <template #left>
        <div class="flex items-center">
          <router-link tag="button" :to="{ name: 'EventActivities', params: { tour: route.params.tour }}" class="relative rounded-full bg-blue-700 p-1 text-blue-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-blue-gray-800">
            <ChevronLeftIcon class="h-6 w-6"></ChevronLeftIcon>
          </router-link>
        </div>
      </template>
      {{ eventStore.selectedActivity?.code }}
    </Header>
    
    <!-- Lecteur de QR code -->
    <div class="relative" ref="qrcodeReader" :style="qrcodeReaderStyle">
      <qrcode-stream
          :formats="['qr_code']"
          :track="paintBoundingBox"
          :paused="pauseQrCodeReader"
          :torch="enableQrCodeTorch"
          @camera-on="onCameraInit"
          @error="onError"
          @detect="onDetect">
      </qrcode-stream>
      <div v-if="qrcodeError" class="text-red-500">{{ qrcodeError }}</div>
      <div :class="[
        'absolute top-0 left-0 right-0 bottom-0 flex flex-col  content-center   items-center justify-center',
        bgColor(result.type),
        ]"
        v-if="result"
        @click="onDiscartResult">
        <CheckCircleIcon v-if="result.type === SUCCESS_RESULT_TYPE" class="h-24 w-24 text-white"></CheckCircleIcon>
        <ExclamationCircleIcon v-if="result.type === WARNING_RESULT_TYPE" class="h-24 w-24 text-white"></ExclamationCircleIcon>
        <XCircleIcon v-if="result.type === ERROR_RESULT_TYPE" class="h-24 w-24 text-white"></XCircleIcon>
        <h3 class="mt-3 text-xl text-center font-bold text-white">{{ result.code }}</h3>
          <!-- Détails du Check-in -->
        <div class="mt-1 text-center font-semibold text-white">
          <div v-if="result.checkIn" class="my-4">
          <div class="text-xl font-bold">{{ result.checkIn.order_item.registration[0]?.firstname }} {{ result.checkIn.order_item.registration[0]?.lastname }}</div>
          <div>{{ result.checkIn.order_item.activity.code }}</div>
          <div>{{ result.checkIn.order_item.discounted_price.amount }} {{ result.checkIn.order_item.discounted_price.currency }}</div>
        </div>
        <div v-else>{{ result.message }}</div>
        </div>
      </div>
    </div>

    <!-- Compteur de check-in -->
    <div class="bg-gray-50 border-b border-gray-100 px-4 py-4 flex justify-between items-center">
      <span class="text-xl text-gray-400">{{ totalCheckIns }} / {{ eventStore.selectedVariant?.order_items_count}}</span>
      <div>
        <div class="flex items-center gap-x-1.5">
          <div v-if="networkStatusAvailable" :class="[
            'flex-none rounded-full p-1',
             isOnline ? 'bg-emerald-500/20' : 'bg-red-600/20',
            ]">
            <div :class="[
              'h-1.5 w-1.5 rounded-full',
              isOnline ? 'bg-emerald-500' : 'bg-red-600',
            ]"></div>
          </div>
          <p class="text-xs leading-5 text-gray-500">{{ isOnline ? 'Online' : 'Offline' }}</p>
        </div>
      </div>
    </div>

    <div class="bg-white px-4">
      <ul  class="divide-y divide-gray-100">
        <li v-for="action in actionHistory" class="text-sm flex items-center justify-arround  gap-x-6 py-5">
          <div>
            <div :class="[
              'rounded-sm flex items-center justify-center h-8 w-8',
              bgColor(action.type),
              ]">
              <CheckCircleIcon v-if="action.type === SUCCESS_RESULT_TYPE" class="h-4 w-4 text-white"></CheckCircleIcon>
              <ExclamationCircleIcon v-if="action.type === WARNING_RESULT_TYPE" class="h-4 w-4 text-white"></ExclamationCircleIcon>
              <XCircleIcon v-if="action.type === ERROR_RESULT_TYPE" class="h-4 w-4 text-white"></XCircleIcon>
            </div>
          </div>
          <div>
            <strong>{{ action.code }}</strong><br>
            {{ action.message  }}
          </div>
          <div class="ml-auto text-gray-600 ">
            {{ action.timestamp }}
          </div>
        </li>
      </ul>
    </div>

  </div>
</template>

<script setup>
import Header from '@/components/Header.vue';
import { ChevronLeftIcon } from '@heroicons/vue/20/solid'
import { CheckCircleIcon, XCircleIcon, ExclamationCircleIcon } from '@heroicons/vue/24/outline'
import { SUCCESS_RESULT_TYPE, WARNING_RESULT_TYPE, ERROR_RESULT_TYPE, bgColor } from '@/support/checkIn'
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { QrcodeStream } from 'vue-qrcode-reader';
import { useEventStore } from '@/stores/eventStore';
import { useAuthStore } from '@/stores/authStore';
import { useNetwork } from '@vueuse/core'
import axios from 'axios';

const route = useRoute();
const eventStore = useEventStore();
const authStore = useAuthStore();

const orderItemCheckIn = ref({});
const result = ref(null);
const qrcodeError = ref('')
const totalCheckIns = ref(0);
const actionHistory = ref([]);
const pauseQrCodeReader = ref(false);
const enableQrCodeTorch = ref(true);

const { isOnline, isSupported : networkStatusAvailable } = useNetwork();


function onError(err) {
  qrcodeError.value = `[${err.name}]: `
  if (err.name === 'NotAllowedError') {
      qrcodeError.value += 'you need to grant camera access permission'
  } else if (err.name === 'NotFoundError') {
      qrcodeError.value += 'no camera on this device'
  } else if (err.name === 'NotSupportedError') {
      qrcodeError.value += 'secure context required (HTTPS, localhost)'
  } else if (err.name === 'NotReadableError') {
      qrcodeError.value += 'is the camera already in use?'
  } else if (err.name === 'OverconstrainedError') {
      qrcodeError.value += 'installed cameras are not suitable'
  } else if (err.name === 'StreamApiNotSupportedError') {
      qrcodeError.value += 'Stream API is not supported in this browser'
  } else if (err.name === 'InsecureContextError') {
      qrcodeError.value += 'Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.'
  } else {
      qrcodeError.value += err.message
  }
}

function paintBoundingBox(detectedCodes, ctx) {
  for (const detectedCode of detectedCodes) {
    const {
      boundingBox: { x, y, width, height }
    } = detectedCode

    ctx.lineWidth = 2
    ctx.strokeStyle = '#007bff'
    ctx.strokeRect(x, y, width, height)
  }
}

const API_URL = import.meta.env.VITE_API_URL;


// declare a ref to hold the element reference
// the name must match template ref value
const qrcodeReader = ref(null)
const qrcodeReaderStyle = ref({})

const resizeQrcodeReader = () => {
  const width = qrcodeReader.value.offsetWidth
  const height = qrcodeReader.value.offsetHeight
  qrcodeReaderStyle.value = {
    width: `${width}px`,
    height: `${height}px`,
  }
  
}

const onCameraInit = (capabilities) => {
  const TORCH_IS_SUPPORTED = !!capabilities.torch
  enableQrCodeTorch.value = false
  // resizeQrcodeReader()
};
const onDetect = async (content) => {
  console.log('onDetect: ', content);
  const code = content[0]?.rawValue;
  try {
    const response = await axios.post(`${API_URL}/${route.params.tour}/check-in`, {
      code: code,
      activity_variant_id: route.params.variant,
    }, {
      headers: {
        Authorization: `Bearer ${authStore.token}`,
      },
    });

    // Mettez à jour l'objet orderItemCheckIn avec la réponse de l'API
    if (response.data.data) {
      const checkIn = response.data.data;
      result.value = {
        code: code,
        type: SUCCESS_RESULT_TYPE,
        message: checkIn.order_item.registration[0]?.firstname + ' ' + checkIn.order_item.registration[0]?.lastname,
        checkIn: checkIn,
      }
      orderItemCheckIn.value = response.data.data;
      totalCheckIns.value = response.data.meta.total_checked;
    }
  } catch (error) {
    // Gérez les erreurs ici
    console.log('error: ', error);
    if (error.response.status === 422) {
      result.value = {
        code: code,
        type: WARNING_RESULT_TYPE,
        message: Object.entries(error.response.data.message).map((m) => m[1]).join(','),
      }
    } else {
      result.value = {
        code: code,
        type: ERROR_RESULT_TYPE,
        message: error.response.data.message,
      }
    }
    resizeQrcodeReader()
    pauseQrCodeReader.value = true;
  }
  const stream = [
    {
      ...result.value,
      timestamp: new Date().toLocaleString(),
    },
    ...actionHistory.value,
  ].splice(-10);
  actionHistory.value = stream;
};

const onDiscartResult = () => {
  result.value = null;
  pauseQrCodeReader.value = false;
};


onMounted(async () => {
  const tourSlug = route.params.tour;
  const variantId = route.params.variant;
  if (!eventStore.selectedEvent || eventStore.selectedEvent.slug !== tourSlug) {
    await eventStore.fetchEventBySlug(tourSlug);
  }
  await eventStore.fetchActivities(tourSlug);
  await eventStore.selectVariant(variantId);

  totalCheckIns.value = eventStore.selectedVariant?.checked_in_order_items_count;
});

</script>
