<template>
    <svg viewBox="0 0 3125 3125" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2">
    <clipPath id="a">
        <path d="M3338.54-60.417H-213.54v3552.08h3552.08V-60.417Zm-1707.37 2001.01 942.486-942.486c66.513-40.654 134.678-50.21 206.021 0 0 0 252.299 249.93 254.173 252.099 70.996 82.162 30.431 199.624-36.312 249.929-8.865 6.681-1259.81 1259.81-1259.81 1259.81-67.435 40.53-129.408 55.64-210.757 0L863.916 2096.89c-52.883-72.718-49.239-154.519 2.368-216.638l243.909-242.765c55.053-43.437 120.833-52.398 201.285-14.208l319.688 317.319Z"/>
    </clipPath>
    <g clip-path="url(#a)">
    <clipPath id="b">
    <path d="M1406.52 328.487h648.213v1900.17H1406.52z"/>
    </clipPath>
    <g clip-path="url(#b)">
    <path d="m2054.73 867.626 972.841 1348.1h-1945.68l972.842-1348.1Z" fill="#fff" fill-opacity=".65"/>
    </g>
    <clipPath id="c">
    <path d="M2054.73 328.487h1011.98v1900.17H2054.73z"/>
    </clipPath>
    <g clip-path="url(#c)">
    <path d="m2054.73 867.626 972.841 1348.1h-1945.68l972.842-1348.1Z" fill="#fff"/>
    </g>
    <clipPath id="d">
    <path d="M1332.69 46.027h1231.72v2579.9H1332.69z"/>
    </clipPath>
    <g clip-path="url(#d)">
    <path d="m1332.69 515.312 1231.72 1698.92H100.98L1332.7 515.312Z" fill="#fff"/>
    </g>
    <clipPath id="e">
    <path d="M-238.65 46.027h1571.34v2579.9H-238.65z"/>
    </clipPath>
    <g clip-path="url(#e)">
    <path d="m1332.69 515.312 1231.72 1698.92H100.98L1332.7 515.312Z" fill="#fff" fill-opacity=".65"/>
    </g>
</g>
<path d="m1631.24 2625.93-634.622-634.622 222.118-222.118 412.504 412.505 1047.13-1047.13 222.118 222.118-1269.24 1269.24Z" fill="#fff" fill-rule="nonzero"/>
</svg>
</template>