<template>
    <div class="bg-blue-700 sticky top-0 z-30 w-full  shadow-lg">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="flex h-16 items-center justify-between">
                <div class="flex">
                    <slot name="left"></slot>
                </div>
                <div class="flex items-center">
                    <span class="text-white font-bold text-md text-center">
                        <slot>Check In</slot>
                    </span>
                </div>
                <div class="-mr-2 flex">
                    <button class="relative rounded-full bg-blue-700 p-1 text-blue-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-blue-gray-800"
                    @click="logout"
                    >
                        <ArrowRightOnRectangleIcon class="h-6 w-6"></ArrowRightOnRectangleIcon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ArrowRightOnRectangleIcon } from '@heroicons/vue/24/outline'
import { useAuthStore } from '@/stores/authStore'; // Présumant que vous avez un store d'authentification
import { useRouter } from 'vue-router';

const authStore = useAuthStore();
const router = useRouter();

const logout = () => {
    authStore.logout(); // Votre méthode de store pour gérer la déconnexion
    router.push('/'); // Redirection vers la page de connexion après la déconnexion
};
</script>
