<template>
  <div class="min-h-screen flex flex-col items-center justify-center bg-blue-700">
    <div>
      <DigitalTestCenterCheck class="w-80 h-80"></DigitalTestCenterCheck>
    </div>
    <form @submit.prevent="submitLogin" class="w-full max-w-xs">
      <div>
        <label for="email" class="sr-only">Email</label>
        <input type="email" id="email" v-model="credentials.email" class="w-full p-2 border border-gray-300 rounded mb-3" placeholder="Email" required>
      </div>
      <div>
        <label for="password" class="sr-only">Password</label>
        <input type="password" id="password" v-model="credentials.password" class="w-full p-2 border border-gray-300 rounded mb-3" placeholder="Password" required>
      </div>

      <div v-if="errorMessage" class="mb-3 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
      {{ errorMessage }}
      </div>

      <button type="submit" class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Login</button>
    </form>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/authStore';
import DigitalTestCenterCheck from '@/components/icons/DigitalTestCenterCheck.vue';

const router = useRouter();
const authStore = useAuthStore();
const credentials = ref({ email: '', password: '' });
const errorMessage = ref(''); // Ajout d'une propriété pour stocker les messages d'erreur

const submitLogin = async () => {
  try {
    await authStore.login(credentials.value);
    // Rediriger l'utilisateur après le login avec le routeur de vue
    router.push({ name: 'Events' });
    errorMessage.value = ''; // Réinitialiser le message d'erreur si la connexion réussit
  } catch (error) {
    // Gérer l'erreur de login ici et montrer un message à l'utilisateur
    errorMessage.value = error.response?.data?.error || error.message || 'Login error.';
  }
};
</script>
