// stores/authStore.js
import { defineStore } from "pinia";
import axios from "axios";

const API_URL = import.meta.env.VITE_API_URL;

export const useAuthStore = defineStore("auth", {
  state: () => ({
    token: null,
  }),
  getters: {
    isAuthenticated: (state) => !!state.token,
  },
  actions: {
    async login(credentials) {
      try {
        const response = await axios.post(`${API_URL}/auth/login`, credentials);
        this.token = response.data.token;
        localStorage.setItem("authToken", this.token);
      } catch (error) {
        console.error("Error on login", error);
        throw error;
      }
    },
    logout() {
      this.token = null;
      localStorage.removeItem("authToken");
    },
    initializeStore() {
      this.token = localStorage.getItem("authToken");
    },
  },
});
