<template>
  <div>
    <Header></Header>
    <div class="bg-white px-4 sm:px-6 lg:px-8">
      <ul role="list" class="divide-y divide-gray-100">
        <li
          v-for="event in eventStore.events"
          :key="event.id"
          @click="selectEvent(event)"
          class="relative flex justify-between gap-x-6 py-5"
        >
          <div class="flex min-w-0 gap-x-4">
            <img class="h-12 w-12 flex-none rounded-full bg-gray-50" :src="event.media.logo" :alt="event.name" />
            <div class="min-w-0 flex-auto">
              <p class="text-sm font-semibold leading-6 text-gray-900">
                {{ event.name }}
              </p>
              <p class="mt-1 flex text-xs leading-5 text-gray-500">
                <span class="relative truncate">{{ event.group.name }}</span>
              </p>
            </div>
          </div>
          <div class="shrink-0 flex items-center">
            <ChevronRightIcon class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import Header from '@/components/Header.vue';
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import { onMounted } from 'vue';
import { useEventStore } from '@/stores/eventStore';
import { useRouter } from 'vue-router';

const eventStore = useEventStore();
const router = useRouter();

// Récupérer les événements lors du montage du composant
onMounted(async () => {
  await eventStore.fetchEvents();
});

// Pas besoin de déréférencer `events`, utilisez-le directement dans le template
// grâce à la réactivité automatique de Pinia

// La fonction pour sélectionner un événement et naviguer vers ses activités
const selectEvent = (event) => {
  eventStore.setSelectedEvent(event); // Stocke l'événement sélectionné dans le store
  router.push({ name: 'EventActivities', params: { tour: event.slug } });
};
</script>
