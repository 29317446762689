// stores/eventStore.js
import { defineStore } from "pinia";
import axios from "axios";
import { useAuthStore } from "./authStore"; // Importez le store d'authentification

const API_URL = import.meta.env.VITE_API_URL;

export const useEventStore = defineStore("event", {
  state: () => ({
    events: [],
    activities: [],
    selectedEvent: null,
    selectedActivity: null,
    selectedVariant: null,
  }),
  actions: {
    async fetchEvents() {
      const authStore = useAuthStore(); // Utilisez le store d'authentification
      try {
        const response = await axios.get(`${API_URL}/event`, {
          headers: {
            // Utilisez le token depuis l'état du store d'authentification
            Authorization: `Bearer ${authStore.token}`,
          },
        });
        this.events = response.data.data;
      } catch (error) {
        console.error("Error fetching events", error);
        throw error;
      }
    },
    async fetchActivities(tourSlug) {
      const authStore = useAuthStore(); // Assurez-vous que vous avez importé useAuthStore
      try {
        const response = await axios.get(`${API_URL}/${tourSlug}/activities`, {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        });
        // Mettre à jour l'état avec les activités récupérées
        this.activities = response.data.data;
      } catch (error) {
        console.error("Error fetching activities", error);
        this.activities = []; // Réinitialiser les activités en cas d'erreur
        throw error;
      }
    },

    async fetchEventBySlug(slug) {
      if (!this.events.length) {
        await this.fetchEvents(); // Assurez-vous que la liste des événements est chargée
      }
      const foundEvent = this.events.find((e) => e.slug === slug);
      this.setSelectedEvent(foundEvent); // Définissez l'événement trouvé comme sélectionné
    },

    setSelectedEvent(event) {
      this.selectedEvent = event;
    },

    selectVariant(variantId) {
      this.selectedActivity = this.activities.find((activity) => {
        return activity.variants.find((v) => {
          return parseInt(variantId) === v.id;
        });
      });
      console.log("selectedActivity ", this.selectedActivity);
      this.selectedVariant = this.selectedActivity.variants.find(
        (v) => parseInt(variantId) === v.id
      );
    },
  },
});
